export default {
  theme: {
    extend: {
      colors: {
        semantic: {
          primary: {
            default: '#0AC16A',
            'variant-light': '#193560',
            'variant-highlight': '#180C33',
            'variant-dark': '#0AC16A',
            'on-default': '#193560',
            'on-light': '#FFFFFF'
          },
          secondary: {
            default: '#193560',
            'variant-light': '#193560',
            'variant-highlight': '#180C33',
            'variant-dark': '#193560',
            'on-default': '#193560',
            'on-light': '#FFFFFF'
          },          
          surface: {
            default: '#FFFFFF',
            'on-surface': '#193560',
            'on-surface-variant': '#666766',
            'container': '#F0F0F0',
            'container-lowest': '#F9F9F9',
            'container-low': '#F4F5F7',
            'container-high': '#E8EBEF'
          },
          inverse: {
            'surface': '#0B1D39',
            'surface-light': '#193560',
            'on-surface': '#FFFFFF',
            'primary': '#0AC16A',
            'on-primary': '#193560'
          },
          outline: {
            default: '#D2D2D2',
            'variant': '#D2D2D2',
            'variant-dark': '#D2D2D2',
            'variant-light': '#FFFFFF'
          },
          states: {
            error: {
              default: '#C50E0E',
              variant: '#FFE1E1',
              'on-default': '#FFFFFF',
              'on-variant': '#C50E0E'
            },
            warning: {
              default: '#FFE847',
              variant: '#FFFCE7',
              'on-default': '#FFFFFF',
              'on-variant': '#FFE847'
            },
            success: {
              default: '#0AC16A',
              variant: '#F2FFF9',
              'on-default': '#FFFFFF',
              'on-variant': '#0AC16A'
            },
            info: {
              default: '#74CEFF',
              variant: '#EFF9FF',
              'on-default': '#FFFFFF',
              'on-variant': '#74CEFF'
            },
            disabled: {
              default: '#F0F0F0',
              'on-default': '#AAAAAA'
            }
          }
        }
      }
    }
  }
}
