
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index82Fp0NPbBEI7qzi6LpTZN0B9nB1FMqrgsEvOIkqvrgUMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/index.vue?macro=true";
import { default as loginwRw7UcLSMpvbgGm4MUBqJPG14Vo8KzA_TIQZIukwBGMMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verify3BZA20JIiRk2yiWIZW3tlKRXC0waWBN97DANphiXzEcMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemapxr7c1R4WRhm78H_458SNWK8Js7iAtvggPnShNUel2R_IkMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45usBP64ZpxnbCqGqKy3a_45RD5Hz_45R7PTien50D9UT_dq5U0Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as register79D2K4APZBc9EiZ5fSVF_DaR4ayEPoNecaWuc2sI3CoMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaintsFPuN1OwwKFkG55uomOcwEGrpHcFT5NVntp4rgHoFY1MMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usC0HVarcQkNr3dvqGRJhrxyi2kzaZBJam8mWbCMn6guoMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as indexjTYU0HMqzZOI3zP1DLGDIAm3VDbfjP60VFdj_m7x9ngMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as indexIe3FjSX8jhdhZG_bGc5ccfkUXR2NFaA5gRerngPtYlYMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93i9RafjPg9isHhBv_45S_45a6bAAk9YP82SB1zS9QHqBwVqwMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipsJGF_45Ghb2WANxwmxmGsmyd7DQJAq9ycUDJSQW3Wio9SYMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45useH7GS0_m53nD7R0LyrLBTkjQVLjNfV2_f21gms3DSuQoMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as indexvf1zS5ziQe_45TkMUPd3Y_45XCVUL2qObYOCQsN04ozQUEYMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policyTSIgLRYP9S_L2l8DBi4OZps_45McLfLXrHmE_I3sHQC6sMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passworduqWJ7s_45lQq3W8cA3Qgr4N3_45EZLbI8XMf_45Q44LzBC3LsMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexkcURohxxssGpSjl707RXYhxTID_45qwtZT_h8YkEN_Sy4Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as index2Yzbn73_EeVLgwBzZ7sh8MVNFzftddAH0XsmJIH_EQYMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45passwordnuADP_WMSeobZx9_45rjJu_45TmeNaN3Tlbc7foC0kcZ0JsMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as indexGVxXd4VNATZI4Bg7WbQEDFO2QEB2oog5PGkuQtemMLgMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93HK80L3JAEk2RHBb21nmGMJd8zElnWyPWP0R_8DpggU4Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guidesPv62gxiLOIP5xyXSvAsdlzEWxWejjCFUOP5ohfPJRFYMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticesmqoLSYW5GHr1khh1JsjNYuFtIO6xzWKposEcAVd4uUMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexi1E30fE_45iustYWV_8sOFExnlSB_DoxS0Qg45dF5AC5gMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45passwordN8Yo122_45jgyqVYVhlS5sW_45nDK10C4QcoHkHXpTBcXtoMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45back6QEIlJ0VCwvtZjrlXR95_457w8nm3hc2QNvSKUMWYEJgoMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as mergewsdtacDQv9dHXUHasolRuDaPDOigp3L9NKHNQkbsqXkMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexkyQiZY2raME0wAjo05upltAMrUN3HAVFWCwVA_rF7rEMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_93mC7iq767uczwlMJQzmiEA6UNOTOJb0CmPLAZWjMrD_45UMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45you2mJsEoX4jTMCAZ1YpkL6G6FopQOupFibvfMPdu7hDDoMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as indexr6xNE1hunw_45prCHepl6awAt2hFXukkrbgp3z1CwRiBgMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indexXR_1UjJ10PkgESLFWmw4NZj6fl18v1lit_45nDwI2qqyQMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93ZI3CBvaXnUiIfT46zpjgvY9Q1_45WbW4xk7OWWuBXn4SMMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as indexVxAUrP1YYSz6_S_45pa9Oujj7mqkaR7_pRS0nuj21Q0uwMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexFOnaq3cRGcjFe0M5ReYIK5CXmaYm5VVcnFdPa1jZgi4Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93yRLKANu8HYBexrEN6_fzW_yy8aQliKrq1agMlUpdM6IMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbackiSnsJhj8zql9IgF4IyAvv8MUyqqhoJSPBDIJ97Iu_450AMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claimhQGA0hwB_45B8tJnd7kGZVh1E8DWBUWEQegxDE1yn6yzcMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as index_xJT06DKWo_7Xg_45JEtcu6npeowFBA2FWA62c6WV3ZQUMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youto6SFGP7wnMe6PTDDsUThR3bZSiWxEmlOof1rZpw4scMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as error9DR2ljEQKtw9MlQdPxfC2ND6noqVXT9BurED2fXCeVsMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as index1iStkFyJDlfaSMY8dW_oAu3JT4ndzF34LN2h9DuAs_0Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as indexGTeHmsdZxOMQBIKKJjyV6_Ah_dK2WO5wDLmxYIsh3kUMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as errorgXkdEAR10gEt8VH_JTiPnUmXuV8HKmh1fXfWZ2lNgiAMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93pf_JcEKxhbvEXm_45rw2IH2aKn_45HWM7rpHXMFmqTInXwUMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsJK7qWAW_45Ir761YITRCa78sdZ2gEUIx19mck3epDbv5QMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45ratesE0jCTS0SJXbD287sZemAlgmWWG6L3LKCepu85u88Z2MMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93CZFMY8_459DrhagnA8zq3Mno6aQ4qwtNU4hN3yfQgdaa4Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexCoyTNShtyCwksnLNntXhqJQhkpEh6JFP6eS3_32WFagMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settings_eMRp739037QMqHUEySP_45bcHW6dYL4LiulCGHyhVkYYMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexNyLqhVTtRH5sEbsVLrUEmE1ax82bGWzHLlLCRvsnT8kMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chat1MDc1rExaJeN6JN2oyyalouLx9Z0_cbEE2N5e091LT8Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45salewKKWA_45QLe_45WOWqs2NJIFRQzHMzB4UEZRwAi6y8t9R5cMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45youQTa9cn4SSFywIzLUmlfBPx_pKawMvXZnMm6_45LY8db2MMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensivecT2mQw88Q0h3aNcuBqHwDbYjCidzlvkSXaxR_45H84WkQMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_93_45F1puTuKi_5otp9TohGPjN8LSmnCgjPIPPbE7IM1u_cMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as addOGQqAVHUdqsIN2_xEljnceJ31rFISI0TKz5rKAjWsscMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexoHuEEVFkyP3ls1BQ_4586YJFUc5idrjdNsKWjzxs29zpIMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_93vPq2ObNNSf1h2OmgLO7bCNtQg9hK87OllCQqOGmxO6AMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callbackoibKlKhHu57iVh_OtwHLwsG1LI_ZIKHiVRQb0MaBBqQMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93pdu0PeBRK2A_45v48FWVpmS_45q6VjDQDEmKXkOuyK5G2K4Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45youjPCFl5S09ldQJcqEovnAt4uBD9BRyJzyhbdDaypluLoMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45bHh0lq3gfVPes3vwgz3m9_45aYV7LtMZ6HTuSmPJdi8gLMMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexhkKW8Yu7clcOo6gomfHXzzvMpjF8jAx3A11Opriax7IMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93retBAFnMgkdCGWUp88IKxKiakrP6s9KglFwv8nAbSKsMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93gmpTjnLSNT_skawey4haYKDSx_45Rp6oh8P94fAAwED8QMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as index6VIwWcZf2tpOXpzYyd6m7PEaDETg_yxIPBlJOChSyDoMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addSxuzF4uJyUBWKNw5Y4JPwej6zDi_45zYIBx7jZx9It_YoMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_934iAP4_DISmWvNJweSUAQnEtiB35dWgkM_45Zvt_B4BlPUMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as detailsroViddrBzDsXvG3wU_R1R__450_IzdckY5Uf7_45nM_45wXpIMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as digital_45eicgUAw2LgdlONA6CP_45h5v1jZUtOzIyEqxsWMx006LT630Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45youL6FtcxylE6D0NjKUZM_45Aiegn8dAxsi969TajoxJIwl0Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93T4TYlr8_45G9Rtiu_45lfKL2DpHIAYHQbPfRnT1hFieQDgEMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93oqe_paTqiTDtUzuaPNrzS4yfyMcVpoyJtww6krB0CvIMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as step1hUyYiQ27rH3R3LrPCwwE95QcoGPwGmr7kH_soRDT4MgMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue?macro=true";
import { default as detailskMw0srbLq571ztzAey5IGMxvOH1_QqLT0qxjwSlXfOcMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsLNYNYKwb5MSLYbfhqJzU41xNYb8PqfABB_D43Z6R7JoMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexs2rWJWccr9952PnzwM_snOdjEKMxuB_45QXH4exlXMHK8Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signup4TNKfu9v7XOfRasAAf5E7D2ME3XKBA4CY7Cxi_2nKwQMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45youvTzw3UXkeMtx7ELKmC7qESVwi2CZSizvZdtY_45QPMSpYMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93t9mLDl8GUV9koYDr5wx5WqkSA3VZvVwSStEAXr4ecOAMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93IlGo3uFsJdwAHDQ0ulHf9tQuoqj_l7XSWDgisQF_45wwIMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0vpkh_tGzBz1NOAcvNGNt_10pDC3jc3v_45piaSkhnMQDEMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customers0CIFzXee6fuW4rSqElgUtoJwz6uvb3qWxVhAFNHoHjQMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45youvvT8lAWnnckcNASxOSrTBJG2CJbHV75jN1FHZn7RD6wMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as detailshnUUQyY_45KH7JGFcP9_k0HVUbJSaTfNNrhKA_4523E9jQQMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as detailswLkW9ZBVGS919MsPGR4hjqUmp__45YNeV_456uSLyGPc8goMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_93x0KRy1fCh6LKByzIDurg7lVy_45LF4rt7nag_7ckUpj5QMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebateNFlGqtbu_45z5iMN4Y_2FSynaH_eZDJHrE9puKgzPBAccMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_93Y2aDxrZtopkik5rWDvSS2YtTdozVFtMPpDnojJ9E8UQMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailsLTHGh6rvWT9frc8XjT8uE7XcdmwvX1puhYG7I1C1PiMMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0qp3phcYWFf_3al_45lV7kVJoN9HKmf33Nwn4wmdl_Lq5sMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as index4N52FYwattViDnIxhw1pEvpp3A5_Ei0rEk3fZHVSaggMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as index8LAz3VLSEXRYq9XE48nweXHMZAR_IF60qaBhodWV2IQMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as detailsTz0N9Eh3QUWw328lzyWREkhXMyghrBq6i2knl3bbg_45kMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionslr70fIqj7CPSbNf_3o3nwK_451Lx0BbbI3QjDCAvZRe8EMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as indexqAymu2HJVwkt99TtBD2524h0Uw692XweVk5uQkrmMXEMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as detailsJtm8Kb3H5jeWhilF5zgDHQH9XY7Csv_W_45k3SGRKDfxQMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as indexWFL50S9k8f3UCn_nDwh4fzvudnng2DYuhGMMETY8Qu4Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successrGdmX__QXnAG5jHQryK1rHhwE79oU49NG7SZNIFsvlIMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offer7tGIzMpB9HFEDtfiPj_gUNQ6ebB6KsS301NoMgmLy88Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailsZrgPHm9jemRSENZam5uGfWyunxQHJF6SDdTKePNvk0cMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbour8Tm1gpGcUi7J_aSbupLxuVQmYSNdC6GkJLFuPUN9e_cMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93QQmjZhZ2V5zUYa_45oKHcq2b3jFKJGJMhQbvpo_Yl10coMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as indexuqGGPmExw1sNSdo6of9LZPqaRV69_pgs4ClYgmeqGooMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_93VA0_XVQEpGfl_sq3lIIIDpjJxWAiJELCdj3kLtebrboMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexNKghvo_45US2jJBUpXhTf_82FrYytzmulc7_lzpIb2I_0Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_93STpGw2LPJuhIHqEJ2mna_45BWJQb7xrmZHAE4edPAZwd0Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as indexhXdcd6PBcNwcmxfRkr_45I8txr8AvwIlnBBMv2lOik1WEMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as indexSNXznSZJWETGe_AQZD8U6NvCSJkA5KpZwpWXJpMw3mQMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsZTzNoVit4Nu6IENvjtlR1QfgLXMJPAyNjpxVv0xRaygMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expired0qhaKF6BLeqiv5uHISyFa8ZIdv1_45xrFHjhUyWyHSUUUMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexb80sLdkj7laEyCbzhCLTv_45GHeDDMaM6Gu_7r0PoQ_45EcMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45youXNR_451ZtGHbgxNRzDPXLcnQZKHqspmtbvdsiOHUZpqkQMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as detailsqrA9vkB4tOQlrmy8AZ3hihmIBsvNby50UebIirbUadEMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as confirmationQZeMXCjh1P4OJct0YP6qADuJmtGzCSK3nrejQlNCXmUMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionH5kmzVqv0WjC_FyMczBdb1yM3YaYWKc_2wIj6HzFWVQMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultste8J_a5yE14wTjqlUEgGSfWsP3vYbKGCbMuMt85lODkMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmationOd5CEPHj2byOt14mq0LQPzgyM9BKQua46ephrBJkBE0Meta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93KijXXTsDdcBW7Iov3OjLF0P3qFLLT9aI3yun91BXh6QMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93BbmHOITFXfodEjkv6MyQDehIUYhZ9EhGpMpwLXg9nbUMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93TiwqLyOb2aEtBI_V_459IaMtYV1CWSECdPept1Q_45rdEDQMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_93sHGgYFhV9iENDkjbxx_45fRS9c9Nj4dmQSpTnM5xXfg_cMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as index8kZs2rAn9v4hwXQPVlVTD5Ga1ghzwKyBpbhi21ON8doMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45detailsbC3_45hMBkRXlxG9_4588gL1MIysg_2HGTVAg_45biPFGxk2AMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_93ojS70P_Vp4hqwsbZD209ZYnrg_45oukiSc_nXGGvGC4dUMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_936MW_bDoTbBn77TFgf34DmvuEIx4BnTFdYAeeXgEWmXQMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as indexUKRCwv4DbPoONk3ScaPJIevDF5EpOoLhNB1fLFIQ9gAMeta } from "/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginwRw7UcLSMpvbgGm4MUBqJPG14Vo8KzA_TIQZIukwBGMMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: register79D2K4APZBc9EiZ5fSVF_DaR4ayEPoNecaWuc2sI3CoMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passworduqWJ7s_45lQq3W8cA3Qgr4N3_45EZLbI8XMf_45Q44LzBC3LsMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: index2Yzbn73_EeVLgwBzZ7sh8MVNFzftddAH0XsmJIH_EQYMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordnuADP_WMSeobZx9_45rjJu_45TmeNaN3Tlbc7foC0kcZ0JsMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordN8Yo122_45jgyqVYVhlS5sW_45nDK10C4QcoHkHXpTBcXtoMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93mC7iq767uczwlMJQzmiEA6UNOTOJb0CmPLAZWjMrD_45UMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexVxAUrP1YYSz6_S_45pa9Oujj7mqkaR7_pRS0nuj21Q0uwMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: index_xJT06DKWo_7Xg_45JEtcu6npeowFBA2FWA62c6WV3ZQUMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexGTeHmsdZxOMQBIKKJjyV6_Ah_dK2WO5wDLmxYIsh3kUMeta?.props ?? {"verificationNeeded":true},
    meta: indexGTeHmsdZxOMQBIKKJjyV6_Ah_dK2WO5wDLmxYIsh3kUMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settings_eMRp739037QMqHUEySP_45bcHW6dYL4LiulCGHyhVkYYMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexNyLqhVTtRH5sEbsVLrUEmE1ax82bGWzHLlLCRvsnT8kMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callbackoibKlKhHu57iVh_OtwHLwsG1LI_ZIKHiVRQb0MaBBqQMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-form-step1",
    path: "/life-insurance/quote/form/step1",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebateNFlGqtbu_45z5iMN4Y_2FSynaH_eZDJHrE9puKgzPBAccMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: detailsTz0N9Eh3QUWw328lzyWREkhXMyghrBq6i2knl3bbg_45kMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offer7tGIzMpB9HFEDtfiPj_gUNQ6ebB6KsS301NoMgmLy88Meta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailsZrgPHm9jemRSENZam5uGfWyunxQHJF6SDdTKePNvk0cMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93QQmjZhZ2V5zUYa_45oKHcq2b3jFKJGJMhQbvpo_Yl10coMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionH5kmzVqv0WjC_FyMczBdb1yM3YaYWKc_2wIj6HzFWVQMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93KijXXTsDdcBW7Iov3OjLF0P3qFLLT9aI3yun91BXh6QMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93BbmHOITFXfodEjkv6MyQDehIUYhZ9EhGpMpwLXg9nbUMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_93sHGgYFhV9iENDkjbxx_45fRS9c9Nj4dmQSpTnM5xXfg_cMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45detailsbC3_45hMBkRXlxG9_4588gL1MIysg_2HGTVAg_45biPFGxk2AMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_93ojS70P_Vp4hqwsbZD209ZYnrg_45oukiSc_nXGGvGC4dUMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_936MW_bDoTbBn77TFgf34DmvuEIx4BnTFdYAeeXgEWmXQMeta || {},
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src2241883317/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]