export default {
  theme: {
    extend: {
      colors: {
        semantic: {
          primary: {
            default: '#361564',
            'variant-light': '#671CC2',
            'variant-highlight': '#614983',
            'variant-dark': '#1E0C39',
            'on-default': '#FFFFFF',
            'on-light': '#FFFFFF'
          },
          secondary: {
            default: '#671CC2',
            'variant-light': '#361564',
            'variant-highlight': '#614983',
            'variant-dark': '#1E0C39',
            'on-default': '#FFFFFF',
            'on-light': '#FFFFFF'
          },
          surface: {
            default: '#FFFFFF',
            'on-surface': '#1E0C39',
            'on-surface-variant': '#8C8C89',
            container: '#E2E1E0',
            'container-lowest': '#FBF7FF',
            'container-low': '#EFE8F8',
            'container-high': '#E1D2F3'
          },
          inverse: {
            surface: '#1E0C39',
            'surface-light': '#361564',
            'on-surface': '#FFFFFF',
            primary: '#CBFE50',
            'on-primary': '#1E0C39'
          },
          outline: {
            default: '#C5C5C2',
            variant: '#E2E1E0',
            'variant-dark': '#1E0C39',
            'variant-light': '#FFFFFF'
          },
          states: {
            error: {
              default: '#A3000B',
              variant: '#FCE8EC',
              'on-default': '#FFFFFF',
              'on-variant': '#700007'
            },
            warning: {
              default: '#CC7700',
              variant: '#FFFCE5',
              'on-default': '#FFFFFF',
              'on-variant': '#663B00'
            },
            success: {
              default: '#0D7A12',
              variant: '#E5FAE6',
              'on-default': '#FFFFFF',
              'on-variant': '#084A0B'
            },
            info: {
              default: '#0057AC',
              variant: '#E5ECFB',
              'on-default': '#FFFFFF',
              'on-variant': '#003E7A'
            },
            disabled: {
              default: '#F1F0F0',
              'on-default': '#8C8C89'
            }
          }
        }
      }
    }
  }
}
